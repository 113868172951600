import React from "react";

import { Grid } from "@material-ui/core";

import { OrderDetailsInterface } from "../../config/redux/common/commonSlice.types";

import MapContainer from "../../common/GoogleMapContainer";

interface RenderTrackingMapDetailsInterface {
  stateObject: any;
  orderDetails: OrderDetailsInterface | null;
  handleCurrentZoomLevel: any;
}

const RenderTrackingMapDetails = (props: RenderTrackingMapDetailsInterface) => {
  const { stateObject, orderDetails, handleCurrentZoomLevel } = props;

  return (
    <Grid container className="mapContainer">
      <MapContainer
        center={stateObject.mapCenter}
        zoom={stateObject.currentZoomLevel}
        showDirections={stateObject.showDirections}
        markers={stateObject.mapMarkers}
        places={stateObject.mapPlaces}
        updateMap={stateObject.updateMap}
        source={stateObject?.location?.source || undefined}
        status={orderDetails?.trackingInfo?.status}
        handleCurrentZoomLevel={handleCurrentZoomLevel}
      />
    </Grid>
  );
};

export default React.memo(RenderTrackingMapDetails);

import React from "react";

import { Grid, SvgIcon, Typography } from "@material-ui/core";

import { isHyperMaxCountry, isUndefined } from "../../utils/helpers.utils";
import { OrderDetailsInterface } from "../../config/redux/common/commonSlice.types";

import { ReactComponent as hyperMaxCancelledIcon } from "./../../assets/icons/hyper-max-cancelled-icon.svg";
import { ReactComponent as cancelledIcon } from "./../../assets/icons/cancelled-icon.svg";

interface RenderOrderCancelledInterface {
  orderDetails: OrderDetailsInterface | null;
  language: "en" | "ar";
}

const RenderOrderCancelled = (props: RenderOrderCancelledInterface) => {
  const { orderDetails, language } = props;

  const getMainMessageText = () => {
    if (orderDetails?.trackingInfo?.progress?.length! > 0) {
      const progressObject = orderDetails?.trackingInfo?.progress[0];
      if (progressObject?.title && !isUndefined(progressObject?.title[language])) {
        return progressObject?.title[language];
      }
    }
    return "";
  };

  const getSecondaryMessageText = () => {
    if (orderDetails?.trackingInfo?.progress?.length! > 0) {
      const progressObject = orderDetails?.trackingInfo?.progress[0];
      if (progressObject?.description && !isUndefined(progressObject?.description[language])) {
        return progressObject?.description[language];
      }
    }
    return "";
  };

  return (
    <>
      <Grid container className="deliveryFailedContainer mobileView">
        {isHyperMaxCountry(orderDetails?.countryCode) ? (
          <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 289" component={hyperMaxCancelledIcon} />
        ) : (
          <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 320" component={cancelledIcon} />
        )}
      </Grid>
      <Grid container className="deliveryFailedTextContainer mobileView">
        <Typography className="deliveryTextFailed ">{getMainMessageText()}</Typography>
        <Typography className="deliveryTextFailed deliveryTextFailed1">{getSecondaryMessageText()}</Typography>
      </Grid>

      <Grid container className="deliveryFailedTextContainerWeb webView">
        <Typography className="deliveryTextFailedWeb orderCancelled">{getMainMessageText()}</Typography>
        <Typography className="deliveryTextFailedWeb1 orderCancelled">{getSecondaryMessageText()}</Typography>
      </Grid>
      <Grid container className="deliveryFailedContainerWeb webView">
        {isHyperMaxCountry(orderDetails?.countryCode) ? (
          <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 289" component={hyperMaxCancelledIcon} />
        ) : (
          <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 320" component={cancelledIcon} />
        )}
      </Grid>
    </>
  );
};

export default React.memo(RenderOrderCancelled);

import React from "react";

import { Grid, SvgIcon, Typography } from "@material-ui/core";

import { OrderDetailsInterface, ProgressItemInterface } from "../../config/redux/common/commonSlice.types";
import { isHyperMaxCountry, isUndefined } from "../../utils/helpers.utils";
import { getClassName, getRadiusClassName, isProgressBarAvailable } from "../../utils/orderTracking.util";

import { ReactComponent as deliveryTimelineIcon } from "./../../assets/icons/delivery-timeline-icon.svg";

import { ReactComponent as orderPlacedIcon } from "./../../assets/icons/order-placed-icon.svg";
import { ReactComponent as orderBeingPrepared } from "./../../assets/icons/order-prepairing-icon.svg";
import { ReactComponent as orderOntheWayIcon } from "./../../assets/icons/order-on-the-way-icon.svg";
import { ReactComponent as orderDeliveredIcon } from "./../../assets/icons/order-delivered-icon.svg";
// HyperMax
import { ReactComponent as hyperMaxOrderPlacedIcon } from "./../../assets/icons/hyper-max-order-placed-icon.svg";
import { ReactComponent as hyperMaxOrderBeingPrepared } from "./../../assets/icons/hyper-max-order-prepairing-icon.svg";
import { ReactComponent as hyperMaxOrderOntheWayIcon } from "./../../assets/icons/hyper-max-order-on-the-way-icon.svg";

import CommonConstants from "../../constants/CommonConstats";
import AppConstants from "../../constants/AppConstants";

interface RenderTrackingProgressBarInterface {
  orderDetails: OrderDetailsInterface | null;
  language: "en" | "ar";
  deliveryStepStyleClass: any;
  isRTL: boolean;
}

const RenderTrackingProgressBar = (props: RenderTrackingProgressBarInterface) => {
  const { orderDetails, language, deliveryStepStyleClass, isRTL } = props;

  const getProgressObjectText = (progressObject: ProgressItemInterface) => {
    if (!isUndefined(progressObject.status)) {
      switch (progressObject.status) {
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_PLACED:
          if (progressObject?.title && progressObject?.title[language]) {
            return progressObject?.title[language];
          }
          return AppConstants[language].DELIVERY_STATUS.ORDER_PLACED;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_BEING_PREPARED:
          if (progressObject?.title && progressObject?.title[language]) {
            return progressObject?.title[language];
          }
          return AppConstants[language].DELIVERY_STATUS.ORDER_BEING_PREPARED;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_ON_THE_WAY:
          if (progressObject?.title && progressObject?.title[language]) {
            return progressObject?.title[language];
          }
          return AppConstants[language].DELIVERY_STATUS.ORDER_ON_THE_WAY;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_DELIVERED:
          if (progressObject?.title && progressObject?.title[language]) {
            return progressObject?.title[language];
          }
          return AppConstants[language].DELIVERY_STATUS.ORDER_DELIVERED;
        default:
          return <></>;
      }
    }
    return "";
  };

  const getHyperMaxSvgComponent = (progressObject: ProgressItemInterface) => {
    if (!isUndefined(progressObject?.status)) {
      switch (progressObject.status) {
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_PLACED:
          return <SvgIcon className="deliveryIcon placed" viewBox="0 0 20 20" component={hyperMaxOrderPlacedIcon} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_BEING_PREPARED:
          return <SvgIcon className="deliveryIcon preparing" viewBox="0 0 20 20" component={hyperMaxOrderBeingPrepared} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_ON_THE_WAY:
          return <SvgIcon className="deliveryIcon onTheWay" viewBox="0 0 21 17" component={hyperMaxOrderOntheWayIcon} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_DELIVERED:
          return <SvgIcon className="deliveryIcon delivered" viewBox="0 0 19 14" component={orderDeliveredIcon} />;
        default:
          return <></>;
      }
    }
    return <></>;
  };

  const getSvgComponent = (progressObject: ProgressItemInterface) => {
    if (!isUndefined(progressObject?.status)) {
      switch (progressObject.status) {
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_PLACED:
          return <SvgIcon className="deliveryIcon placed" viewBox="0 0 20 20" component={orderPlacedIcon} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_BEING_PREPARED:
          return <SvgIcon className="deliveryIcon preparing" viewBox="0 0 20 20" component={orderBeingPrepared} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_ON_THE_WAY:
          return <SvgIcon className="deliveryIcon onTheWay" viewBox="0 0 20 20" component={orderOntheWayIcon} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_DELIVERED:
          return <SvgIcon className="deliveryIcon delivered" viewBox="0 0 19 14" component={orderDeliveredIcon} />;
        default:
          return <></>;
      }
    }
    return <></>;
  };

  return (
    <Grid className="progressItem progressHeader" item>
      <Grid container className="headerContainer webView">
        {isProgressBarAvailable(orderDetails) &&
          orderDetails?.trackingInfo?.progress?.map((progressObject: ProgressItemInterface, progressObjectIndex: number) => (
            <Grid item className={`headerItem ${progressObjectIndex === orderDetails?.trackingInfo?.progress?.length - 1 ? "noGrow" : ""}`} key={`${progressObjectIndex}-progress-text`}>
              <Grid className="headerItemContainer" container>
                {isHyperMaxCountry(orderDetails?.countryCode) ? getHyperMaxSvgComponent(progressObject) : getSvgComponent(progressObject)}
                <Typography className="deliveryText placed">{getProgressObjectText(progressObject)}</Typography>
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Grid container className="headerContainer webView">
        {isProgressBarAvailable(orderDetails) &&
          orderDetails?.trackingInfo?.progress?.map((progressObject: ProgressItemInterface, progressObjectIndex: number) => (
            <Grid item className="headerItem" key={`${progressObjectIndex}-progress-item`}>
              <Grid className="headerItemContainer" container>
                <Grid className={`deliveryStep ${getClassName(orderDetails, progressObject)} ${getRadiusClassName(orderDetails, isRTL, progressObject)}`}>
                  {progressObjectIndex !== orderDetails?.trackingInfo?.progress?.length - 1 && <SvgIcon className={deliveryStepStyleClass} viewBox="0 0 5 5" component={deliveryTimelineIcon} />}
                </Grid>
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderTrackingProgressBar);

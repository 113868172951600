import React from "react";

import { Grid, SvgIcon } from "@material-ui/core";

import { isHyperMaxCountry } from "../../utils/helpers.utils";
import { OrderDetailsInterface } from "../../config/redux/common/commonSlice.types";

import { ReactComponent as hyperMaxDeliveredIcon } from "./../../assets/icons/hyper-max-delivered-icon.svg";
import { ReactComponent as deliveredIcon } from "./../../assets/icons/delivered-icon.svg";

interface RenderOrderDeliveredInterface {
  orderDetails: OrderDetailsInterface | null;
}

const RenderOrderDelivered = (props: RenderOrderDeliveredInterface) => {
  const { orderDetails } = props;

  return (
    <Grid container className="deliveredIconContainer">
      {isHyperMaxCountry(orderDetails?.countryCode) ? (
        <SvgIcon className="deliveredIcon" viewBox="0 0 300 289" component={hyperMaxDeliveredIcon} />
      ) : (
        <SvgIcon className="deliveredIcon" viewBox="0 0 320 320" component={deliveredIcon} />
      )}
    </Grid>
  );
};

export default React.memo(RenderOrderDelivered);

const CommonConstants = {
  OMS_STATUS: {
    WAITING: "WAITING",
    READY: "READY",
    EXPORTED: "EXPORTED",
    PICKING_STARTED: "PICKING_STARTED",
    PACKING_COMPLETE: "PACKING_COMPLETE",
    READY_TO_DISPATCH: "READY_TO_DISPATCH",
    DISPATCHED: "DISPATCHED",
    PENDING_DELIVERY: "PENDING_DELIVERY",
    DELIVERED: "DELIVRED",
    DELIVERY_FAILED: "DELIVERY_FAILED",
    CANCELLED: "CANCELLED",
  },
  OMS_TERMINAL_STATUS: ["DELIVERY_FAILED", "CANCELLED", "DELIVRED", "DELIVERED"],
  FF_TERMINAL_STATUS: ["DELIVERY_FAILED", "CANCELLED", "DELIVERED", "PARTIALLY_DELIVERED"],
  FF_STATUS: {
    NEW: "NEW",
    OUT_FOR_DELIVERY: "OUT_FOR_DELIVERY",
    ON_HOLD: "ON_HOLD",
    PICKING_STARTED: "PICKING_STARTED",
    PACKING_COMPLETE: "PACKING_COMPLETE",
    READY_TO_DISPATCH: "READY_TO_DISPATCH",
    DISPATCHED: "DISPATCHED",
    PENDING_DELIVERY: "PENDING_DELIVERY",
    DELIVERED: "DELIVERED",
    DELIVERY_FAILED: "DELIVERY_FAILED",
    CANCELLED: "CANCELLED",
    PARTIALLY_DELIVERED: "PARTIALLY_DELIVERED",
  },
  //
  HIDE_HEADER_SECTION_FOR_STATUS: ["DELIVERY_FAILED", "CANCELLED", "ORDER_DELIVERY_FAILED", "ORDER_CANCELLED"],
  HIDE_MAP_SECTION_FOR_STATUS: ["DELIVERY_FAILED", "CANCELLED", "ORDER_DELIVERY_FAILED", "ORDER_CANCELLED", "ORDER_DELIVERED"],
  PROGRESS_BAR_STATUS: {
    ORDER_PLACED: "ORDER_PLACED",
    ORDER_BEING_PREPARED: "ORDER_BEING_PREPARED",
    ORDER_ON_THE_WAY: "ORDER_ON_THE_WAY",
    ORDER_DELIVERED: "ORDER_DELIVERED",
    ORDER_PENDING_DELIVERY: "ORDER_PENDING_DELIVERY",
    ORDER_DELIVERY_FAILED: "ORDER_DELIVERY_FAILED",
    ORDER_CANCELLED: "ORDER_CANCELLED",
  },
  DATE_FORMAT_BACKEND: "YYYY-MM-DDTHH:mm:ss",
  TIME_FORMAT_UI_AM_PM: "hh:mm A",
  JORDAN_COUNTRY_CODE: "JO",
  ARABIC_LANGUAGE: "ar",
  ARRIVAL_INFO_TEXT: {
    ARRIVAL_TIME: "Arrival Time",
    ARRIVING_IN: "Arriving by",
    ARRIVED: "Arrived",
  },
};

export default CommonConstants;

/* global google */
import React, { useRef, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useLanguageHook from "hooks/useLanguage.hook";

import { withGoogleMap, GoogleMap, withScriptjs, Marker, Polyline } from "react-google-maps";

import { CustomMarker, CustomLocation } from ".";
import { AppState } from "../../config/redux/reducers";
import CommonConstants from "constants/CommonConstats";

interface CustomGoogleMapProps {
  markers: CustomMarker[];
  places: CustomMarker[];
  showDirections?: boolean;
  roundTrip?: boolean;
  optimizeRoute?: boolean;
  updateMap?: boolean;
  defaultCenter: CustomLocation;
  defaultZoom: number;
  status?: any;
  handleCurrentZoomLevel: (zoomLevel: any) => void;
  source?: string;
}
const CustomGoogleMap = withScriptjs(
  withGoogleMap((props: CustomGoogleMapProps) => {
    const { markers, defaultCenter, defaultZoom, showDirections, status, handleCurrentZoomLevel, source } = props;
    const { durations, polylinePointsOFD, orderDetails } = useSelector((state: AppState) => state.common);

    const language = useLanguageHook();

    const mapRef = useRef<GoogleMap>(null);

    const [positionOne, setPositionOne] = useState(new google.maps.LatLng(0, 0));
    const [positionTwo, setPositionTwo] = useState(new google.maps.LatLng(0, 0));
    const [mapCenter, setMapCenter] = useState(defaultCenter);
    const [isDragged, setIsDragged] = useState(false);
    const [polylineOptionsInfo, setPolylineOptionsInfo] = useState({});
    const [polyLinePath, setPolyLinePath] = useState([] as any);

    const handleZoomChanged = () => {
      const currentZoomLevel = mapRef.current?.getZoom();
      if (currentZoomLevel && currentZoomLevel !== defaultZoom) {
        handleCurrentZoomLevel(currentZoomLevel);
      }
    };

    const handleOnDragEnd = () => {
      const currentMapCenterLat = mapRef.current?.getCenter().lat();
      const currentMapCenterLng = mapRef.current?.getCenter().lng();
      if (currentMapCenterLat && currentMapCenterLng) {
        const currentMapCenter = { lat: currentMapCenterLat, lng: currentMapCenterLng };
        if (currentMapCenter && (currentMapCenterLat !== mapCenter.lat || currentMapCenterLng !== mapCenter.lng)) {
          setMapCenter(currentMapCenter);
          setIsDragged(true);
        }
      }
    };

    useEffect(() => {
      if (!durations && !isDragged) {
        const bounds = new google.maps.LatLngBounds();
        markers.forEach((marker) => {
          const position = new google.maps.LatLng(marker?.location?.lat || 0, marker?.location?.lng || 0);
          bounds.extend(position);
        });
        mapRef.current?.fitBounds(bounds);
        if (markers && Array.isArray(markers) && markers?.length > 0) {
          setPositionOne(new google.maps.LatLng(markers[0]?.location?.lat || 0, markers[0]?.location?.lng || 0));
          setPositionTwo(new google.maps.LatLng(markers[1]?.location?.lat || 0, markers[1]?.location?.lng || 0));
        }
      }
    }, [markers, durations, mapCenter, isDragged]);

    const B1 = (t: any) => t * t * t;
    const B2 = (t: any) => 3 * t * t * (1 - t);
    const B3 = (t: any) => 3 * t * (1 - t) * (1 - t);
    const B4 = (t: any) => (1 - t) * (1 - t) * (1 - t);

    const getBezier = useCallback((C1: any, C2: any, C3: any, C4: any, percent: any) => {
      let pos = { x: 0, y: 0 };
      pos.x = C1.x * B1(percent) + C2.x * B2(percent) + C3.x * B3(percent) + C4.x * B4(percent);
      pos.y = C1.y * B1(percent) + C2.y * B2(percent) + C3.y * B3(percent) + C4.y * B4(percent);
      return pos;
    }, []);

    const GmapsCubicBezier = useCallback(
      (latlong1: any, latlong2: any, latlong3: any, latlong4: any, resolution: any) => {
        let lat1 = latlong1.lat();
        let long1 = latlong1.lng();
        let lat2 = latlong2.lat();
        let long2 = latlong2.lng();
        let lat3 = latlong3.lat();
        let long3 = latlong3.lng();
        let lat4 = latlong4.lat();
        let long4 = latlong4.lng();

        let points = [];

        for (let it = 0; it <= 1; it += resolution) {
          points.push(getBezier({ x: lat1, y: long1 }, { x: lat2, y: long2 }, { x: lat3, y: long3 }, { x: lat4, y: long4 }, it));
        }
        let path = [];
        for (let i = 0; i < points.length - 1; i++) {
          path.push(new google.maps.LatLng(points[i].x, points[i].y));
          path.push(new google.maps.LatLng(points[i + 1].x, points[i + 1].y, false));
        }
        setPolyLinePath(path);
        setPolylineOptionsInfo({
          path: path,
          geodesic: true,
          strokeOpacity: 0.0,
          icons: [{ icon: { path: "M 0,-1 0,1", strokeOpacity: 1, scale: 4 }, offset: "0", repeat: "20px" }],
          strokeColor: "grey",
        });
      },
      [getBezier]
    );

    useEffect(() => {
      if (mapRef.current) {
        let lineLength = google.maps.geometry.spherical.computeDistanceBetween(positionOne, positionTwo);
        let lineHeading = google.maps.geometry.spherical.computeHeading(positionOne, positionTwo);
        let markerA = new google.maps.Marker({
          position: google.maps.geometry.spherical.computeOffset(positionOne, lineLength / 3, lineHeading + 60),
          icon: {
            url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png",
            size: new google.maps.Size(7, 7),
            anchor: new google.maps.Point(3.5, 3.5),
          },
        });
        let markerB = new google.maps.Marker({
          position: google.maps.geometry.spherical.computeOffset(positionTwo, lineLength / 3, lineHeading + 120),
          icon: {
            url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png",
            size: new google.maps.Size(7, 7),
            anchor: new google.maps.Point(3.5, 3.5),
          },
        });
        GmapsCubicBezier(positionOne, markerA.getPosition(), markerB.getPosition(), positionTwo, 0.01);
      }
    }, [positionOne, positionTwo, GmapsCubicBezier]);

    return (
      <GoogleMap
        key={Math.floor(Math.random() * 1000)}
        defaultZoom={defaultZoom}
        ref={mapRef}
        onZoomChanged={handleZoomChanged}
        onDragEnd={handleOnDragEnd}
        options={{
          fullscreenControl: false,
          zoomControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          clickableIcons: false,
          styles: [{ elementType: "labels", featureType: "poi", stylers: [{ visibility: "off" }] }],
        }}
      >
        {markers.length &&
          markers.map((marker: CustomMarker) => (
            <React.Fragment key={`${marker.name}-${Math.floor(Math.random() * 1000)}`}>
              {marker.name !== "Driver" ? (
                <Marker title={marker.name} icon={{ url: marker.icon, anchor: new google.maps.Point(35, 32) }} label={marker.label} position={marker.location} />
              ) : (
                <Marker title={marker.name} icon={marker.icon} label={marker.label} position={marker.location} />
              )}
            </React.Fragment>
          ))}
        {source ? (
          <>
            {status === language.DELIVERY_STATUS.ORDER_PLACED || status === language.DELIVERY_STATUS.ORDER_BEING_PREPARED ? (
              <Polyline path={polyLinePath} options={polylineOptionsInfo} />
            ) : status === CommonConstants.PROGRESS_BAR_STATUS.ORDER_PLACED ||
              status === CommonConstants.PROGRESS_BAR_STATUS.ORDER_BEING_PREPARED ||
              status === CommonConstants.PROGRESS_BAR_STATUS.ORDER_PENDING_DELIVERY ? (
              <Polyline path={polyLinePath} options={polylineOptionsInfo} />
            ) : (
              <></>
            )}
            {showDirections && markers && markers?.length > 1 ? (
              status === language.DELIVERY_STATUS.ORDER_ON_THE_WAY && polylinePointsOFD ? (
                <Polyline path={google.maps.geometry.encoding.decodePath(polylinePointsOFD)} options={{ strokeColor: "#0B5AA6" }} />
              ) : status === CommonConstants.PROGRESS_BAR_STATUS.ORDER_ON_THE_WAY && orderDetails?.driverRouteInfo?.polylinePoints ? (
                <Polyline path={google.maps.geometry.encoding.decodePath(orderDetails?.driverRouteInfo?.polylinePoints)} options={{ strokeColor: "#0B5AA6" }} />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </>
        ) : (
          <Polyline path={polyLinePath} options={polylineOptionsInfo} />
        )}
      </GoogleMap>
    );
  })
);

export default CustomGoogleMap;

import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import qs from "qs";
import useLanguageHook from "hooks/useLanguage.hook";

import { Grid } from "@material-ui/core";

import { useStyles } from "./OrderTracking.styles";
import { AppState } from "../config/redux/reducers";
import { fetchOrderDetails } from "../config/redux/common/commonSlice";
import { isHyperMaxCountry, isUndefined } from "../utils/helpers.utils";
import { getLanguageFromUrl } from "../utils/orderTracking.util";
import { CustomerContactAddressInfoInterface, DriverRouteInfoInterface, StoreContactAddressInfoInterface } from "../config/redux/common/commonSlice.types";

import hubIcon from "./../assets/icons/hub-icon.svg";
import customerIcon from "./../assets/icons/customer-icon.svg";
import trackerIcon from "./../assets/icons/tracker-icon.svg";
import hyperMaxHubIcon from "./../assets/icons/hyper-max-hub-icon.svg";
import hyperMaxCustomerIcon from "./../assets/icons/hyper-max-customer-icon.svg";
import hyperMaxTrackerIcon from "./../assets/icons/hyper-max-tracker-icon.svg";

import AppConstants from "../constants/AppConstants";
import CommonConstants from "../constants/CommonConstats";
import Loader from "../common/Loader";

import RenderTrackingTitle from "./common/RenderTrackingTitle";
import RenderTrackingProgressBar from "./common/RenderTrackingProgressBar";
import RenderTrackingArrivalDetails from "./common/RenderTrackingArrivalDetails";
import RenderTrackingMapDetails from "./common/RenderTrackingMapDetails";
import RenderTrackingFooterSection from "./common/RenderTrackingFooterSection";
import RenderOrderDelivered from "./common/RenderOrderDelivered";
import RenderOrderDelivereryFailed from "./common/RenderOrderDelivereryFailed";
import RenderOrderCancelled from "./common/RenderOrderCancelled";
import RenderTrackingTechnicalIssue from "./common/RenderTrackingTechnicalIssue";
import RenderMobileOrderTrackingStatus from "./common/RenderMobileOrderTrackingStatus";
import RenderMobileOrderTrackingDetails from "./common/RenderMobileOrderTrackingDetails";

interface OrderTrackingVersionTwoProps {
  location?: any;
  match?: any;
}

interface omsStatusProps {
  statusCode?: string;
  statusName?: string;
  statusDate?: string;
}

const OrderTrackingVersionTwo: any = (props: OrderTrackingVersionTwoProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { location } = props;
  const urlParams = new URLSearchParams(location.search);
  const language = getLanguageFromUrl(urlParams) || "en";
  const isRTL = language === "ar";

  const deliveryStepStyleClass = useMemo(() => (isRTL ? "deliveryStepIconLeft" : "deliveryStepIconRight"), [isRTL]);
  const directionRightStyleClass = useMemo(() => (isRTL ? "directionRight" : ""), [isRTL]);

  const { loading, orderDetails } = useSelector((state: AppState) => state.common);

  const [stateObject, setStateObject] = useState<any>({
    location: null,
    mapCenter: { lat: 0, lng: 0 },
    currentZoomLevel: 13,
    showDirections: false,
    mapMarkers: [],
    mapPlaces: [],
    updateMap: false,
    callCenterNumber: "---",
  });

  const hubGeoLocation = useRef({ name: "Hub", icon: isHyperMaxCountry(orderDetails?.countryCode) ? hyperMaxHubIcon : hubIcon, location: { lat: 0, lng: 0 } });
  const custGeoLocation = useRef({ name: "Customer", icon: isHyperMaxCountry(orderDetails?.countryCode) ? hyperMaxCustomerIcon : customerIcon, location: { lat: 0, lng: 0 } });
  const driverGeoLocation = useRef({ name: "Driver", icon: { url: isHyperMaxCountry(orderDetails?.countryCode) ? hyperMaxTrackerIcon : trackerIcon, rotation: 0 }, location: { lat: 0, lng: 0 } });

  const getOrderStatusData = () => {
    const payload: any = location.search ? qs.parse(location.search, { ignoreQueryPrefix: true, decoder: (c) => c }) : { code: "" };
    setStateObject({
      ...stateObject,
      location: payload,
      mapCenter: { lat: 0, lng: 0 },
      currentZoomLevel: 13,
      showDirections: false,
      mapMarkers: [],
      mapPlaces: [],
      updateMap: false,
      callCenterNumber: "---",
    });
    dispatch(fetchOrderDetails({ consignmentCode: payload.code, source: payload?.source }));
  };

  const handleCurrentZoomLevel = (zoomLevel: any) => {
    setStateObject({ ...stateObject, currentZoomLevel: zoomLevel });
  };

  const showTrackingSection = () => {
    return !loading && !isUndefined(orderDetails?.trackingInfo?.status) && CommonConstants.HIDE_HEADER_SECTION_FOR_STATUS.indexOf(orderDetails?.trackingInfo?.status!) === -1;
  };

  const showMapContainer = () => {
    return !loading && !isUndefined(orderDetails?.trackingInfo?.status) && CommonConstants.HIDE_MAP_SECTION_FOR_STATUS.indexOf(orderDetails?.trackingInfo?.status!) === -1;
  };

  const isOrderDeliveryFailed = () => {
    return orderDetails?.trackingInfo?.status === CommonConstants.PROGRESS_BAR_STATUS.ORDER_DELIVERY_FAILED;
  };

  const showTechnicalIssue = () => {
    return (
      !loading &&
      orderDetails?.trackingInfo?.status !== CommonConstants.PROGRESS_BAR_STATUS.ORDER_DELIVERED &&
      orderDetails?.trackingInfo?.status !== CommonConstants.PROGRESS_BAR_STATUS.ORDER_DELIVERY_FAILED &&
      orderDetails?.trackingInfo?.status !== CommonConstants.PROGRESS_BAR_STATUS.ORDER_CANCELLED
    );
  };

  const updateStateObject = () => {
    const mapCenter = {
      lat: Number(orderDetails?.customerContactInfo?.address?.latitude) || 0,
      lng: Number(orderDetails?.customerContactInfo?.address?.longitude) || 0,
    };

    let mapMarkers: any = [];
    let mapPlaces: any = [];
    let updateMap: boolean = false;
    let showDirections: boolean = false;

    const customerAddress: CustomerContactAddressInfoInterface | undefined = orderDetails?.customerContactInfo?.address;
    if (customerAddress) {
      custGeoLocation.current = {
        ...custGeoLocation.current,
        name: orderDetails?.customerContactInfo?.name || "",
        location: { lat: customerAddress ? Number(customerAddress.latitude) : 0, lng: customerAddress ? Number(customerAddress.longitude) : 0 },
      };
      mapPlaces.push(custGeoLocation.current);
      mapMarkers.push(custGeoLocation.current);
    }

    const hubLocationAddress: StoreContactAddressInfoInterface | undefined = orderDetails?.storeInfo?.contactInfo?.address;
    if (hubLocationAddress) {
      hubGeoLocation.current = {
        ...hubGeoLocation.current,
        name: orderDetails?.storeInfo?.store || "",
        location: {
          lat: hubLocationAddress ? Number(hubLocationAddress.latitude) : 0,
          lng: hubLocationAddress ? Number(hubLocationAddress.longitude) : 0,
        },
      };
      mapPlaces.push(hubGeoLocation.current);
      mapMarkers.push(hubGeoLocation.current);
    }

    const driverAddress: DriverRouteInfoInterface | undefined = orderDetails?.driverRouteInfo;
    if (driverAddress) {
      updateMap = true;
      showDirections = true;
      driverGeoLocation.current = {
        ...driverGeoLocation.current,
        location: {
          lat: driverAddress ? Number(driverAddress.latitude) : 0,
          lng: driverAddress ? Number(driverAddress.longitude) : 0,
        },
      };
      mapPlaces.push(driverGeoLocation.current);
      mapMarkers.push(driverGeoLocation.current);
    }

    const callCenterNumber = (() => {
      if (orderDetails?.countryCode === CommonConstants.JORDAN_COUNTRY_CODE) {
        return AppConstants[language].NEED_HELP_NUM_JORDAN;
      }
      return orderDetails?.countryCode ? AppConstants[language].NEED_HELP_NUM : "--";
    })();

    setStateObject({
      ...stateObject,
      mapCenter: mapCenter,
      currentZoomLevel: 13,
      showDirections: showDirections,
      mapMarkers: mapMarkers,
      mapPlaces: mapPlaces,
      updateMap: updateMap,
      callCenterNumber: callCenterNumber,
    });
  };

  useEffect(() => {
    updateStateObject();
    let interval: any;
    if (!loading && !isUndefined(orderDetails?.trackingInfo?.status) && CommonConstants.HIDE_MAP_SECTION_FOR_STATUS.indexOf(orderDetails?.trackingInfo?.status!) === -1) {
      interval = setTimeout(() => {
        getOrderStatusData();
      }, 50000);
    }
    return () => {
      clearTimeout(interval);
    };
  }, [orderDetails]);

  useEffect(() => {
    getOrderStatusData();
  }, []);

  return (
    <>
      {loading && <Loader isCountryJordan={isHyperMaxCountry(orderDetails?.countryCode)} />}
      <Grid container className={classes.root}>
        {showTrackingSection() && (
          <Grid className="orderItem heading topHeader webView" item>
            <RenderTrackingTitle orderDetails={orderDetails} language={language} />
            <Grid className={`progressBar ${directionRightStyleClass}`}>
              <Grid container className="progressContainer">
                <RenderTrackingProgressBar orderDetails={orderDetails} language={language} deliveryStepStyleClass={deliveryStepStyleClass} isRTL={isRTL} />
                <RenderTrackingArrivalDetails orderDetails={orderDetails} language={language} />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid className="orderItem map" item>
          {!loading && orderDetails?.trackingInfo?.status === CommonConstants.PROGRESS_BAR_STATUS.ORDER_DELIVERED && <RenderOrderDelivered orderDetails={orderDetails} />}
          {!loading && isOrderDeliveryFailed() && <RenderOrderDelivereryFailed orderDetails={orderDetails} language={language} />}
          {!loading && orderDetails?.trackingInfo?.status === CommonConstants.PROGRESS_BAR_STATUS.ORDER_CANCELLED && <RenderOrderCancelled orderDetails={orderDetails} language={language} />}
          {showMapContainer() ? (
            <RenderTrackingMapDetails stateObject={stateObject} orderDetails={orderDetails} handleCurrentZoomLevel={handleCurrentZoomLevel} />
          ) : showTechnicalIssue() ? (
            <RenderTrackingTechnicalIssue orderDetails={orderDetails} language={language} />
          ) : (
            <></>
          )}
        </Grid>
        {showTrackingSection() && (
          <Grid className="orderItem heading bottomHeading mobileView" item>
            <Grid className="progressBar">
              <Grid container className="progressContainer">
                <RenderMobileOrderTrackingStatus orderDetails={orderDetails} language={language} directionRightStyleClass={directionRightStyleClass} isRTL={isRTL} />
                <RenderMobileOrderTrackingDetails
                  orderDetails={orderDetails}
                  language={language}
                  directionRightStyleClass={directionRightStyleClass}
                  isRTL={isRTL}
                  deliveryStepStyleClass={deliveryStepStyleClass}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {!loading && <RenderTrackingFooterSection stateObject={stateObject} orderDetails={orderDetails} language={language} directionRightStyleClass={directionRightStyleClass} />}
      </Grid>
    </>
  );
};

export default React.memo(OrderTrackingVersionTwo);

import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { OrderDetailsInterface } from "../../config/redux/common/commonSlice.types";
import { getDateBasedFromFormat, isUndefined } from "../../utils/helpers.utils";

import CommonConstants from "../../constants/CommonConstats";

interface RenderTrackingArrivalDetailsInterface {
  orderDetails: OrderDetailsInterface | null;
  language: "en" | "ar";
}

const RenderTrackingArrivalDetails = (props: RenderTrackingArrivalDetailsInterface) => {
  const { orderDetails, language } = props;

  const getArrivalMessage = () => {
    if (language) {
      if (orderDetails?.arrivalInfo?.title && !isUndefined(orderDetails?.arrivalInfo?.title[language])) {
        return orderDetails?.arrivalInfo?.title[language];
      }
    }
    return "";
  };

  const getArrivalTime = () => {
    if (isUndefined(orderDetails?.arrivalInfo?.arrivalTime)) return "---";
    return getDateBasedFromFormat(orderDetails?.arrivalInfo?.arrivalTime, CommonConstants.DATE_FORMAT_BACKEND, CommonConstants.TIME_FORMAT_UI_AM_PM);
  };

  return (
    <Grid className="progressItem progressHeader progressHeaderTimer" item>
      <Grid container className="headerContainer webView">
        <Grid item className="headerItem"></Grid>
        <Grid item className="headerItem"></Grid>
        <Grid item className="headerItem noGrow">
          <Grid className="headerItemContainer" container>
            <Typography className="deliveryTextTimer">{getArrivalMessage()}</Typography>
            <Typography className={`deliveryTextTimerValue`}>{getArrivalTime()}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderTrackingArrivalDetails);

import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { useStyles } from "./Loader.styles";
import { COLORS } from "../../config/theme/baseTheme";

interface LoaderProps {
  isCountryJordan: boolean;
}

const Loader = (props: LoaderProps) => {
  const { isCountryJordan } = props;
  const classes = useStyles();

  return (
    <Grid className={classes.root} container>
      <CircularProgress style={{ color: isCountryJordan ? COLORS.APPLE : COLORS.ALTO_ANOTHER }} />
    </Grid>
  );
};

export default React.memo(Loader);

import React from "react";

import { Grid, SvgIcon, Typography } from "@material-ui/core";

import { OrderDetailsInterface, ProgressItemInterface } from "../../config/redux/common/commonSlice.types";
import { getClassName, getRadiusClassName, getStatusMessage, isProgressBarAvailable } from "../../utils/orderTracking.util";

import { ReactComponent as deliveryTimelineIcon } from "./../../assets/icons/delivery-timeline-icon.svg";

interface RenderMobileOrderTrackingDetailsInterface {
  orderDetails: OrderDetailsInterface | null;
  language: "en" | "ar";
  directionRightStyleClass: any;
  isRTL: boolean;
  deliveryStepStyleClass: any;
}

const RenderMobileOrderTrackingDetails = (props: RenderMobileOrderTrackingDetailsInterface) => {
  const { orderDetails, language, directionRightStyleClass, isRTL, deliveryStepStyleClass } = props;

  return (
    <>
      <Grid className={`progressItem progressTimeline ${directionRightStyleClass}`} item>
        <Grid container className="headerContainer">
          {isProgressBarAvailable(orderDetails) &&
            orderDetails?.trackingInfo?.progress?.map((progressObject: ProgressItemInterface, progressObjectIndex: number) => (
              <Grid item className="headerItem" key={`${progressObjectIndex}-progress-item`}>
                <Grid className="headerItemContainer" container>
                  <Grid className={`deliveryStep ${getClassName(orderDetails, progressObject)} ${getRadiusClassName(orderDetails, isRTL, progressObject)}`}>
                    {progressObjectIndex !== orderDetails?.trackingInfo?.progress?.length - 1 && <SvgIcon className={deliveryStepStyleClass} viewBox="0 0 5 5" component={deliveryTimelineIcon} />}
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid className="progressItem progressMsg" item>
        <Typography className={`progressStatusMsg`}>{`${getStatusMessage(orderDetails, language) || ""}`}</Typography>
      </Grid>
    </>
  );
};

export default React.memo(RenderMobileOrderTrackingDetails);

import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { OrderDetailsInterface } from "../../config/redux/common/commonSlice.types";
import { isUndefined } from "../../utils/helpers.utils";

import AppConstants from "../../constants/AppConstants";
import CommonConstants from "../../constants/CommonConstats";

interface RenderTrackingFooterSectionInterface {
  stateObject: any;
  orderDetails: OrderDetailsInterface | null;
  language: "en" | "ar";
  directionRightStyleClass: any;
}

const RenderTrackingFooterSection = (props: RenderTrackingFooterSectionInterface) => {
  const { stateObject, orderDetails, language, directionRightStyleClass } = props;

  const getMainContainerClass = () => {
    if (!isUndefined(orderDetails?.trackingInfo?.status) && CommonConstants.HIDE_HEADER_SECTION_FOR_STATUS.indexOf(orderDetails?.trackingInfo?.status!) !== -1) {
      return "topPadding16";
    }
    return "";
  };

  const isCountryJordan = () => {
    if (!isUndefined(orderDetails?.countryCode) && orderDetails?.countryCode === CommonConstants.JORDAN_COUNTRY_CODE) {
      return "backgroundForJordan";
    }

    return "";
  };

  const showCustomerSupportText = () => {
    if (!isUndefined(orderDetails?.trackingInfo?.status) && CommonConstants.HIDE_HEADER_SECTION_FOR_STATUS.indexOf(orderDetails?.trackingInfo?.status!) !== -1) {
      return true;
    }
    return false;
  };

  const getAddressLine1 = () => {
    const { customerContactInfo } = orderDetails || {};
    const { address } = customerContactInfo || {};
    if (!address) return "";

    const apartment = address?.apartment || "";
    const building = address?.building || "";
    const streetName = address?.streetName || "";
    const streetNumber = address?.streetNumber || "";
    const area = address?.area || "";
    const zoneNumber = address?.zoneNumber || "";
    const addressParts = [apartment, building, streetName, streetNumber, area, zoneNumber].filter(Boolean); // Remove any falsy values (empty strings)
    return addressParts.join(", ");
  };

  const getAddressLine2 = () => {
    const { customerContactInfo } = orderDetails || {};
    const { address } = customerContactInfo || {};
    if (!address) return "";
    const city = address?.city || "";
    const country = address?.countryName || "";
    return `${city ? city + ", " : ""}${country}`;
  };

  const getAddressLine3 = () => {
    return orderDetails?.customerContactInfo?.address?.landmark ? `Landmark : ${orderDetails?.customerContactInfo?.address?.landmark || ""}` : "";
  };

  return (
    <Grid className={`orderItem desc ${getMainContainerClass()} ${isCountryJordan()}`} item>
      <Grid container className={`descContainer ${directionRightStyleClass}`}>
        <Grid item className="descItem order">
          <Grid container className="orderContainer">
            <Typography className="orderContainerItem orderNo">{AppConstants[language].ORDER_NUMBER}</Typography>
            {showCustomerSupportText() && <Typography className="orderContainerItem orderDate">{AppConstants[language].CUSTOMER_SUPPORT}</Typography>}
          </Grid>
          <Grid container className="orderContainer">
            <Typography className="orderContainerItemValue orderNo">{`${orderDetails?.orderCode || ""}`}</Typography>
            {showCustomerSupportText() && (
              <Typography className="orderContainerItemValue custSupport orderDate contact help">
                <a href={`tel: ${stateObject.callCenterNumber}`}>{stateObject.callCenterNumber}</a>
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item className="descItem delivery">
          <Grid container className="orderDescContainer">
            <Grid item className="orderDescItem">
              <Grid container className="deliveryItemContainer">
                <Typography className="deliveryItem">{AppConstants[language].DELIVERING_TO}</Typography>
              </Grid>
            </Grid>
            <Grid item className="orderDescItem">
              <Typography className="address">{getAddressLine1()}</Typography>
              <Typography className="address">{getAddressLine2()}</Typography>
              <Typography className="address">{getAddressLine3()}</Typography>
            </Grid>
            <Grid item className="orderDescItem">
              <Typography className="phone">
                <span className="contact mobile">
                  <a href={`tel: ${orderDetails?.customerContactInfo?.phone || ""}`}>{orderDetails?.customerContactInfo?.phone || ""}</a>
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderTrackingFooterSection);

import React from "react";

import { Grid, SvgIcon, Typography } from "@material-ui/core";

import { isHyperMaxCountry, isUndefined } from "../../utils/helpers.utils";
import { OrderDetailsInterface } from "../../config/redux/common/commonSlice.types";
import AppConstants from "../../constants/AppConstants";

import { ReactComponent as hyperMaxTechnicalIssueIcon } from "./../../assets/icons/hyper-max-technical-issue-icon.svg";
import { ReactComponent as technicalIssueIcon } from "./../../assets/icons/technical-issue-icon.svg";

interface RenderTrackingTechnicalIssueInterface {
  orderDetails: OrderDetailsInterface | null;
  language: "en" | "ar";
}

const RenderTrackingTechnicalIssue = (props: RenderTrackingTechnicalIssueInterface) => {
  const { orderDetails, language } = props;

  return (
    <>
      <Grid container className="deliveryFailedContainer mobileView">
        {isHyperMaxCountry(orderDetails?.countryCode) ? (
          <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 289" component={hyperMaxTechnicalIssueIcon} />
        ) : (
          <SvgIcon className="deliveryFailedIcon technicalIssue" viewBox="0 0 320 320" component={technicalIssueIcon} />
        )}
      </Grid>
      <Grid container className="deliveryFailedTextContainer mobileView">
        <Typography className="deliveryTextFailed">{`${AppConstants[language].TECH_ISSUE_MSG1} ${AppConstants[language].TECH_ISSUE_MSG2}`}</Typography>
      </Grid>

      <Grid container className="deliveryFailedTextContainerWeb webView">
        <Typography className="deliveryTextFailedWeb">{AppConstants[language].TECH_ISSUE_MSG1}</Typography>
        <Typography className="deliveryTextFailedWeb1">{AppConstants[language].TECH_ISSUE_MSG2}</Typography>
      </Grid>
      <Grid container className="deliveryFailedContainerWeb webView">
        {isHyperMaxCountry(orderDetails?.countryCode) ? (
          <SvgIcon className="deliveryFailedIcon" viewBox="0 0 320 289" component={hyperMaxTechnicalIssueIcon} />
        ) : (
          <SvgIcon className="deliveryFailedIconWeb" viewBox="0 0 320 320" component={technicalIssueIcon} />
        )}
      </Grid>
    </>
  );
};

export default React.memo(RenderTrackingTechnicalIssue);

import axios from "axios";
import { NetworkCallPayload } from "./networkHandler.types";
import { apigeeLiveTrakingURL, basicApigeeCode, basicAuthorization, liveTrackingUrl } from "../config/Api.config";
import { replaceUrlVariables } from "../utils/updateUrlParams";

const networkDefaultSetting = (urlString: string, source?: string) => {
  axios.defaults.baseURL = urlString || liveTrackingUrl;
  axios.defaults.headers.common["Authorization"] = basicAuthorization;
  if (source) {
    axios.defaults.headers.common["source"] = "portal";
  }
  if (urlString === apigeeLiveTrakingURL) {
    axios.defaults.headers.common["x-api-key"] = basicApigeeCode;
  }
};

const networkHandler = async (params: NetworkCallPayload, urlString: string, source?: string) => {
  const { url, urlParams, ...restApiOptions } = params;
  let updateUrl = url;
  if (urlParams) {
    updateUrl = replaceUrlVariables(url, urlParams);
  }
  networkDefaultSetting(urlString, source);
  return await axios.request({ url: updateUrl, ...restApiOptions });
};

export default networkHandler;

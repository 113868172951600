import React from "react";
import { googleApiKey } from "../../config/Api.config";
import { Grid } from "@material-ui/core";
import CustomGoogleMap from "./CustomGoogleMap";

export interface CustomLocation {
  lat: number;
  lng: number;
}

export interface CustomMarker {
  name: string;
  location: CustomLocation;
  icon?: any;
  label?: any;
}

interface GoogleMapContainerProps {
  center: CustomLocation;
  showDirections?: boolean;
  zoom?: number;
  markers?: CustomMarker[];
  places?: CustomMarker[];
  roundTrip?: boolean;
  optimizeRoute?: boolean;
  updateMap?: boolean;
  status?: any;
  handleCurrentZoomLevel: (zoomLevel: any) => void;
  source?: string;
}

const MapContainer = (props: GoogleMapContainerProps) => {
  const { center, zoom, markers, places, showDirections, roundTrip, optimizeRoute, updateMap, status, handleCurrentZoomLevel, source } = props;
  return (
    <CustomGoogleMap
      googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + googleApiKey + "&libraries=geometry,drawing,places"}
      markers={markers || []}
      places={places || []}
      showDirections={showDirections}
      loadingElement={<Grid style={{ height: `100%`, width: `100%` }} />}
      containerElement={<Grid id="map_canvas" style={{ height: `100%`, width: `100%` }} />}
      mapElement={<Grid style={{ height: `100%`, width: `100%` }} />}
      roundTrip={roundTrip}
      optimizeRoute={optimizeRoute}
      updateMap={updateMap}
      defaultCenter={center || { lat: 0, lng: 0 }}
      defaultZoom={zoom || 13}
      handleCurrentZoomLevel={handleCurrentZoomLevel}
      status={status}
      source={source}
    />
  );
};

export default MapContainer;

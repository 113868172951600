import React from "react";

import { Grid, SvgIcon, Typography } from "@material-ui/core";

import { getDateBasedFromFormat, isHyperMaxCountry, isUndefined } from "../../utils/helpers.utils";
import { OrderDetailsInterface, ProgressItemInterface } from "../../config/redux/common/commonSlice.types";

import CommonConstants from "../../constants/CommonConstats";
import AppConstants from "../../constants/AppConstants";

import { ReactComponent as orderPlacedIcon } from "./../../assets/icons/order-placed-icon.svg";
import { ReactComponent as orderBeingPrepared } from "./../../assets/icons/order-prepairing-icon.svg";
import { ReactComponent as orderOntheWayIcon } from "./../../assets/icons/order-on-the-way-icon.svg";
import { ReactComponent as orderDeliveredIcon } from "./../../assets/icons/order-delivered-icon.svg";
// HyperMax
import { ReactComponent as hyperMaxOrderPlacedIcon } from "./../../assets/icons/hyper-max-order-placed-icon.svg";
import { ReactComponent as hyperMaxOrderBeingPrepared } from "./../../assets/icons/hyper-max-order-prepairing-icon.svg";
import { ReactComponent as hyperMaxOrderOntheWayIcon } from "./../../assets/icons/hyper-max-order-on-the-way-icon.svg";

interface RenderMobileOrderTrackingStatusInterface {
  orderDetails: OrderDetailsInterface | null;
  language: "en" | "ar";
  directionRightStyleClass: any;
  isRTL: boolean;
}

const RenderMobileOrderTrackingStatus = (props: RenderMobileOrderTrackingStatusInterface) => {
  const { orderDetails, language, directionRightStyleClass, isRTL } = props;

  const renderSvgComponent = () => {
    if (!isUndefined(orderDetails?.trackingInfo?.status)) {
      switch (orderDetails?.trackingInfo?.status) {
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_PLACED:
          return <SvgIcon className="deliveryIcon placed" viewBox="0 0 20 20" component={orderPlacedIcon} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_BEING_PREPARED:
          return <SvgIcon className="deliveryIcon preparing" viewBox="0 0 20 20" component={orderBeingPrepared} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_ON_THE_WAY:
          return <SvgIcon className="deliveryIcon onTheWay" viewBox="0 0 20 20" component={orderOntheWayIcon} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_DELIVERED:
          return <SvgIcon className="deliveryIcon delivered" viewBox="0 0 19 14" component={orderDeliveredIcon} />;
        default:
          return <></>;
      }
    }
    return null;
  };

  const renderHyperMaxSvgComponent = () => {
    if (!isUndefined(orderDetails?.trackingInfo?.status)) {
      switch (orderDetails?.trackingInfo?.status) {
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_PLACED:
          return <SvgIcon className="deliveryIcon placed" viewBox="0 0 20 20" component={hyperMaxOrderPlacedIcon} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_BEING_PREPARED:
          return <SvgIcon className="deliveryIcon preparing" viewBox="0 0 20 20" component={hyperMaxOrderBeingPrepared} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_ON_THE_WAY:
          return <SvgIcon className="deliveryIcon onTheWay" viewBox="0 0 21 17" component={hyperMaxOrderOntheWayIcon} />;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_DELIVERED:
          return <SvgIcon className="deliveryIcon delivered" viewBox="0 0 19 14" component={orderDeliveredIcon} />;
        default:
          return <></>;
      }
    }
    return null;
  };

  const getDeliveryTextClassName = () => {
    if (!isUndefined(orderDetails?.trackingInfo?.status)) {
      switch (orderDetails?.trackingInfo?.status) {
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_PLACED:
          return "placed";
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_BEING_PREPARED:
          return "preparing";
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_ON_THE_WAY:
          return "onTheWay";
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_DELIVERED:
          return "delivered";
        default:
          return "";
      }
    }
    return "";
  };

  const getStatusTitle = () => {
    if (!isUndefined(orderDetails?.trackingInfo?.status)) {
      switch (orderDetails?.trackingInfo?.status) {
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_PLACED:
          if (orderDetails?.trackingInfo?.progress?.length && orderDetails?.trackingInfo?.progress?.length > 0) {
            const statusDetails = orderDetails?.trackingInfo?.progress?.find((item: ProgressItemInterface) => item.status === orderDetails?.trackingInfo?.status);
            if (!isUndefined(statusDetails) && statusDetails?.title && statusDetails?.title[language]) {
              return statusDetails?.title[language];
            }
          }
          return AppConstants[language].DELIVERY_STATUS.ORDER_PLACED;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_BEING_PREPARED:
          if (orderDetails?.trackingInfo?.progress?.length && orderDetails?.trackingInfo?.progress?.length > 0) {
            const statusDetails = orderDetails?.trackingInfo?.progress?.find((item: ProgressItemInterface) => item.status === orderDetails?.trackingInfo?.status);
            if (!isUndefined(statusDetails) && statusDetails?.title && statusDetails?.title[language]) {
              return statusDetails?.title[language];
            }
          }
          return AppConstants[language].DELIVERY_STATUS.ORDER_BEING_PREPARED;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_ON_THE_WAY:
          if (orderDetails?.trackingInfo?.progress?.length && orderDetails?.trackingInfo?.progress?.length > 0) {
            const statusDetails = orderDetails?.trackingInfo?.progress?.find((item: ProgressItemInterface) => item.status === orderDetails?.trackingInfo?.status);
            if (!isUndefined(statusDetails) && statusDetails?.title && statusDetails?.title[language]) {
              return statusDetails?.title[language];
            }
          }
          return AppConstants[language].DELIVERY_STATUS.ORDER_ON_THE_WAY;
        case CommonConstants.PROGRESS_BAR_STATUS.ORDER_DELIVERED:
          if (orderDetails?.trackingInfo?.progress?.length && orderDetails?.trackingInfo?.progress?.length > 0) {
            const statusDetails = orderDetails?.trackingInfo?.progress?.find((item: ProgressItemInterface) => item.status === orderDetails?.trackingInfo?.status);
            if (!isUndefined(statusDetails) && statusDetails?.title && statusDetails?.title[language]) {
              return statusDetails?.title[language];
            }
          }
          return AppConstants[language].DELIVERY_STATUS.ORDER_DELIVERED;
      }
    }
    return "";
  };

  const getArrivalMessage = () => {
    if (language) {
      if (orderDetails?.arrivalInfo?.title && !isUndefined(orderDetails?.arrivalInfo?.title[language])) {
        return orderDetails?.arrivalInfo?.title[language];
      }
    }
    return "";
  };

  const getArrivalTime = () => {
    if (isUndefined(orderDetails?.arrivalInfo?.arrivalTime)) return "---";
    return getDateBasedFromFormat(orderDetails?.arrivalInfo?.arrivalTime, CommonConstants.DATE_FORMAT_BACKEND, CommonConstants.TIME_FORMAT_UI_AM_PM);
  };

  return (
    <Grid className="progressItem progressHeader" item>
      <Grid container className={`headerContainer mobileView ${directionRightStyleClass}`}>
        {isHyperMaxCountry(orderDetails?.countryCode) ? renderHyperMaxSvgComponent() : renderSvgComponent()}
        <Typography className={`deliveryText ${getDeliveryTextClassName()}`}>{getStatusTitle()}</Typography>
        <Typography className={`mobileTimerText ${isRTL ? "mobileTimerMarginRtl" : ""}`}>{getArrivalMessage()}</Typography>
      </Grid>
      <Grid container className="headerContainer mobileView">
        <Typography className={`mobileTimerValue ${isRTL ? "mobileTimerMarginRtl" : ""}`}>{getArrivalTime()}</Typography>
      </Grid>
    </Grid>
  );
};

export default React.memo(RenderMobileOrderTrackingStatus);

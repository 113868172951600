import React from "react";
import { Route, Switch } from "react-router-dom";

import OrderTracking from "./OrderTracking";
import OrderTrackingVersionTwo from "./OrderTrackingVersionTwo";
import OrderTrackingMkp from "./OrderTrackingMkpNonFood";
import NotFound from "./common/NotFoundPage";
import AppConstants from "./constants";

const Routes = () => {
  return (
    <Switch>
      <Route path={AppConstants.ROUTES.TRACK_ORDER} component={OrderTrackingVersionTwo} />
      <Route path={AppConstants.ROUTES.TRACKING_ORDER} component={OrderTracking} />
      <Route path={AppConstants.ROUTES.TRACK_ORDER_MKP} component={OrderTrackingMkp} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routes;

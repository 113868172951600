import React from "react";

import { Grid, Typography } from "@material-ui/core";

import { OrderDetailsInterface } from "../../config/redux/common/commonSlice.types";
import { getStatusMessage } from "../../utils/orderTracking.util";

interface RenderTrackingTitle {
  orderDetails: OrderDetailsInterface | null;
  language: "en" | "ar";
}

const RenderTrackingTitle = (props: RenderTrackingTitle) => {
  const { orderDetails, language } = props;

  return (
    <Grid container className="headingContainer webView">
      <Typography className="headingTitle">{`${getStatusMessage(orderDetails, language) || ""}`}</Typography>
    </Grid>
  );
};

export default React.memo(RenderTrackingTitle);

import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { SvgIcon } from "@material-ui/core";

import { useStyles } from "./Header.styles";
import { ReactComponent as mafLogoIcon } from "./../../assets/icons/logo-icon.svg";
import { ReactComponent as HyperMaxLogoIcon } from "./../../assets/icons/HyperMaxLogoIcon.svg";

import { AppState } from "../../config/redux/reducers";
import { isCountryJordan, isHyperMaxCountry, isUndefined } from "../../utils/helpers.utils";

const Header = () => {
  const classes = useStyles();
  const { loading, consignmentData, orderDetails } = useSelector((state: AppState) => state.common);

  const isHyperMaxUser = () => {
    if (!isUndefined(consignmentData) && !isUndefined(consignmentData?.deliveryInformation)) {
      return isCountryJordan(consignmentData);
    } else if (!isUndefined(orderDetails) && !isUndefined(orderDetails?.countryCode)) {
      return isHyperMaxCountry(orderDetails?.countryCode);
    }
    return false;
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Grid container className={isHyperMaxUser() ? classes.hyperMaxRoot : classes.root}>
          <Grid className="headerItem left" item>
            {isHyperMaxUser() ? <SvgIcon className="logoIcon" viewBox="0 0 313 327" component={HyperMaxLogoIcon} /> : <SvgIcon className="logoIcon" viewBox="0 0 138 34" component={mafLogoIcon} />}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withRouter(Header);
